<template>
  <SimplePageNorm title="药方管理">
    <TmpContent style="width:100%;height:100%;"></TmpContent>
  </SimplePageNorm>
</template>

<script>
import SimplePageNorm from '@/components/base/SimplePageNorm'
import TmpContent from './tmp/tmpContent'

export default {
  components: {
    SimplePageNorm,
    TmpContent
  }
}
</script>

<style scoped lang="less">
.search-block {
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
}
</style>